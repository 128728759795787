import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { normalize } from 'normalizr';
import * as schemas from 'common/schemas';
import { handleError } from './utils';
import discardCollections from 'public/data/discardCollections.json';

import { API_KEY, API_ENDPOINT } from 'config';
import { dissocPath, lensProp, map, over } from 'ramda';

axios.defaults.baseURL = API_ENDPOINT || 'http://localhost:5000';
axios.defaults.headers.common['API-KEY'] =
  API_KEY || '0cd4fbb4-e1b2-4475-8385-858630e6fc1d';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.timeout = 100000;

export default {
  auth: {
    register: async (payload) => {
      payload.installation_id = uuidv4(); // added users installation id
      const result = await axios
        .post('/auth/register', payload)
        .catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    registerAnonymous: async () => {
      const payload = { installation_id: uuidv4() }; // added users installation id
      const result = await axios
        .post('/auth/register', payload)
        .catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    login: async (payload) => {
      const result = await axios
        .post('/auth/login', payload)
        .catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    loginGoogle: async (payload) => {
      const payloadIdentity = {
        installation_id: uuidv4(),
        identity: { provider: 'google', token: payload.tokenId },
      };
      const result = await axios
        .post('/auth/register', payloadIdentity)
        .catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    loginFacebook: async (payload) => {
      const payloadIdentity = {
        installation_id: uuidv4(),
        identity: { provider: 'facebook', token: payload.accessToken },
      };
      const result = await axios
        .post('/auth/register', payloadIdentity)
        .catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    fetchUser: async () => {
      const result = await axios.get('/me').catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    resetPasswordRequest: async (payload) => {
      const result = await axios
        .put('/auth/reset-password-email', payload)
        .catch(handleError);
      return result.data;
    },
    resetPassword: async (payload) => {
      const result = await axios
        .put('/auth/reset-password', payload)
        .catch(handleError);
      return result.data;
    },
  },

  collections: {
    // fetchAll: async () => {
    //   const result = await axios
    //     .get('/search', { params: { type: 'collection' } })
    //     .catch(handleError);
    //   const normalized = normalize(result.data, { list: [schemas.collection] });
    //   return normalized;
    // },
    fetchAll: async () => {
      const result = await axios
        .get('/collections', { params: { expand: true } })
        .catch(handleError);
      const normalized = normalize(result.data, { list: [schemas.collection] });
      return normalized;
    },
    fetchOne: async (id) => {
      const result = await axios
        .get(`/collections/${id}`, { params: { expand: true } })
        .catch(handleError);
      return normalize(result.data, schemas.collection);
    },
  },

  discover: {
    fetchAll: async (filters) => {
      const result = await axios
        .get('/search/tags', { params: { ...filters, type: 'collection' } })
        .catch(handleError);
      return normalize(result.data, { list: [schemas.collection] });
    },
  },

  categories: {
    fetchAll: async () => {
      const result = await axios
        .get('/categories', { params: { expand: true } })
        .catch(handleError);
      return normalize(result.data, { list: [schemas.category] });
    },
    fetchOne: async (id) => {
      const result = await axios
        .get(`/categories/${id}`, { params: { expand: true } })
        .catch(handleError);
      return normalize(result.data, schemas.category);
    },
  },

  products: {
    fetchAll: async () => {
      const result = await axios
        .get('/products', { params: { expand: true } })
        .catch(handleError);
      return normalize(result.data, { list: [schemas.product] });
    },
    fetchOne: async (id) => {
      const result = await axios
        .get(`/products/${id}`, { params: { expand: true } })
        .catch(handleError);
      return normalize(result.data, schemas.product);
    },
    fetchOneIntegrations: async (id) => {
      const result = await axios
        .get(`/products/${id}/integrations`)
        .catch(handleError);
      return normalize(result.data, { list: [schemas.integration] });
    },
    fetchSimilar: async (id, payload) => {
      const result = await axios
        .post('/search/similar?&type=product', payload)
        .catch(handleError);
      const data = normalize(result.data, { list: [schemas.product] });

      return { ...data, productId: id };
    },
  },

  elastic: {
    search: async (url, data) => {
      const result = await axios
        .post('/search', data, { params: { ...url } })
        .catch(handleError);
      return normalize(result.data, { list: [schemas.search] });
    },
    searchSuggestion: async () => {
      // const { filter, ...rest } = args;
      // const query = buildQuery(rest);
      const result = await axios
        .get('/search', { params: { q: '', types: 'product' } })
        .catch(handleError);
      return normalize(result.data, { list: [schemas.search] });
    },
  },

  user: {
    updateProfile: async (payload) => {
      const result = await axios.put('/me', payload).catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    updatePassword: async (payload) => {
      const result = await axios
        .put('/me/update-password', payload)
        .catch(handleError);
      return result.data;
    },
    fetchCollections: async () => {
      const result = await axios
        .get('/me/collections', { params: { expand: true } })
        .catch(handleError);
      return normalize(result.data, { list: [schemas.myCollections] });
    },
    addToCollection: async (colId, payload) => {
      const result = await axios
        .post(`/me/collections/${colId}/entries`, payload)
        .catch(handleError);
      return normalize(result.data, schemas.myCollections);
    },
    removeFromCollection: async (colId, itemId) => {
      const result = await axios
        .delete(`/me/collections/${colId}/entries/${itemId}`)
        .catch(handleError);
      return normalize(result.data, schemas.myCollections);
    },
    // TODO: apply api
    fetchDiscardCollections: async () => {
      const data = discardCollections;
      return normalize(data, { list: [schemas.myCollections] });
    },
  },

  stripe: {
    fetchSubscription: async () => {
      const result = await axios.get('/me/subscription').catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    addSubscription: async (payload) => {
      const result = await axios
        .post('/me/subscription', payload)
        .catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    getSubscriptionPortal: async () => {
      const result = await axios
        .get('/me/subscription/portal')
        .catch(handleError);
      return result.data;
    },
    addSubscriptionSession: async (payload) => {
      const result = await axios
        .post('/me/subscription/session', payload)
        .catch(handleError);
      return result.data;
    },
    updateSubscription: async (payload) => {
      const result = await axios
        .put('/me/subscription', payload)
        .catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    cancelSubscription: async () => {
      const result = await axios.delete('/me/subscription').catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    updatePayment: async (payload) => {
      const result = await axios
        .put('/me/subscription_payment_method', payload)
        .catch(handleError);
      return result.data && normalize(result.data, schemas.user);
    },
    getBillingHistory: async () => {
      const result = await axios
        .get('/me/subscription/invoices')
        .catch(handleError);
      return result.data;
    },
  },

  email: {
    subscriber: async (payload) => {
      const result = await axios
        .post('/email-subscriber', payload)
        .catch(handleError);
      return result.data;
    },
    removeSubscriber: async (subscriberEmail) => {
      const result = await axios
        .delete(`/email-subscriber/${subscriberEmail}`)
        .catch(handleError);
      return result.data;
    },
  },

  integrations: {
    shopify: {
      requestAccessToken: async (params) => {
        const result = await axios
          .get('/integration/shopify/callback', { params })
          .catch(handleError);
        return result.data;
      },
    },
    fetchAll: async () => {
      const result = await axios.get('/me/integrations').catch(handleError);
      return normalize(
        over(
          lensProp('list'),
          map(dissocPath(['data', 'entries'])),
          result.data,
        ),
        { list: [schemas.integration] },
      );
    },
    fetchOne: async (id) => {
      const result = await axios
        .get(`/me/integrations/${id}`, { params: { expand: true } })
        .catch(handleError);
      return normalize(result.data, schemas.integration);
    },
    addIntegration: async (payload) => {
      const result = await axios
        .post('/me/integrations', payload)
        .catch(handleError);
      return result;
    },
    addProduct: async (payload) => {
      const result = await axios
        .post('/me/integrations/bulk/entries', payload)
        .catch(handleError);
      return normalize(result.data, { list: [schemas.integration] });
    },
    removeProduct: async (integrationId, productId) => {
      const result = await axios
        .delete(`/me/integrations/${integrationId}/entries/${productId}`)
        .catch(handleError);
      return normalize(result.data, schemas.integration);
    },
    editProduct: async (integrationId, data) => {
      const result = await axios
        .post(`/me/integrations/${integrationId}/entries`, data)
        .catch(handleError);
      return normalize(result.data, schemas.integration);
    },
    getUrlShopifyStore: async (shopName) => {
      const result = await axios
        .get('/integration/shopify', { params: { shop: shopName } })
        .catch(handleError);
      return result.data;
    },
  },
};
