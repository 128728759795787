import {
  SEARCH_TYPES,
  LOAD_MORE_SEARCH_LIST_TYPES,
} from '../actions/actionTypes';

import createReducer from 'common/store/createReducer';
import {
  lensPath,
  pipe,
  path,
  pathOr,
  over,
  defaultTo,
  keys,
  concat,
  assoc,
} from 'ramda';

const initialState = {};

export function searchProductSuccess(state, action) {
  const search = pipe(
    pathOr({}, ['result', 'entities', 'search']),
    keys,
  )(action);
  const total = path(['result', 'result', 'count'], action);
  const limit = pathOr(1, ['result', 'queryParams', 'limit'], action);
  const offset = pathOr(0, ['result', 'queryParams', 'offset'], action);

  return pipe(
    assoc('list', search),
    assoc('total', total),
    assoc('limit', limit),
    assoc('offset', offset),
    assoc('page', offset / limit + 1),
    assoc('totalPages', Math.ceil(total / limit)),
  )(state);
}

export const loadMoreSearchListSuccess = (state, action) => {
  const search = pipe(
    pathOr({}, ['result', 'entities', 'search']),
    keys,
  )(action);
  const total = path(['result', 'result', 'count'], action);
  const limit = pathOr(1, ['result', 'queryParams', 'limit'], action);
  const offset = pathOr(0, ['result', 'queryParams', 'offset'], action);

  return pipe(
    over(lensPath(['list']), pipe(defaultTo([]), concat(search))),
    assoc('total', total),
    assoc('limit', limit),
    assoc('offset', offset),
    assoc('page', offset / limit + 1),
    assoc('totalPages', Math.ceil(total / limit)),
  )(state);
};

export default createReducer(initialState, {
  [SEARCH_TYPES.success]: searchProductSuccess,
  [LOAD_MORE_SEARCH_LIST_TYPES.success]: loadMoreSearchListSuccess,
});
