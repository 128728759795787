import axios from 'axios';
import { setToken, fetchUser } from './actions';

export const middleware = (store) => (next) => (action) => {
  const token = store.getState().auth.token;
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
  return next(action);
};

export async function loadToken(dispatch, token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return await dispatch(setToken(token));
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

export async function loadUser(dispatch) {
  return await dispatch(fetchUser()).catch((error) => {
    throw error.response && error.response.data;
  });
}
