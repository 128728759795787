import createReducer from 'common/store/createReducer';
import * as schemas from 'common/schemas';
import {
  SEARCH_TYPES,
  LOAD_MORE_SEARCH_LIST_TYPES,
  SEARCH_SUGGESTION_RESULTS_UPDATE,
  SAVED_PRODUCT_LIST_UPDATE,
  SAVED_PRODUCT_LIST_REMOVE,
  DISCARD_PRODUCT_LIST_UPDATE,
  DISCARD_PRODUCT_LIST_REMOVE,
  DISCARD_PRODUCT_LIST_ADDED,
  GET_SIMILAR_PRODUCTS,
} from '../actions/actionTypes';
import {
  lensProp,
  over,
  mergeLeft,
  pathOr,
  pipe,
  keys,
  reject,
  equals,
  append,
  prop,
  assocPath,
  mergeDeepRight,
} from 'ramda';

const initialState = Object.values(schemas).reduce((state, entity) => {
  state[entity.key] = {};
  return state;
}, {});

const mergeProduct = (getEntities) => (state, action) => {
  return over(
    lensProp('products'),
    mergeLeft(getEntities(action, state)),
    state,
  );
};

const updateSavedProductList = () => (state, { payload }) => {
  return {
    ...state,
    savedProducts: pipe(pathOr({}, ['entities', 'products']), keys)(payload),
  };
};

const removeSavedProduct = () => (state, action) => {
  return over(
    lensProp('savedProducts'),
    reject(equals(action.payload.id)),
    state,
  );
};

const updateDiscardProductList = () => (state, { payload }) => {
  return {
    ...state,
    discardedProducts: pipe(
      pathOr({}, ['entities', 'products']),
      keys,
    )(payload),
  };
};

const removeDiscardProduct = () => (state, action) => {
  return over(
    lensProp('discardedProducts'),
    reject(equals(action.payload.id)),
    state,
  );
};

const addDiscardProduct = () => (state, action) => {
  return over(lensProp('discardedProducts'), append(action.payload.id), state);
};

const saveSimilarProducts = () => (state, { result }) => {
  const productId = prop('productId', result);
  const list = pathOr([], ['result', 'list'], result);
  const products = pathOr({}, ['entities', 'products'], result);

  return pipe(
    over(lensProp('products'), mergeDeepRight(products)),
    assocPath(['products', productId, 'similarProducts'], list),
  )(state);
};

export default createReducer(initialState, {
  [SEARCH_TYPES.success]: mergeProduct(
    pathOr({}, ['result', 'entities', 'search']),
  ),
  [LOAD_MORE_SEARCH_LIST_TYPES.success]: mergeProduct(
    pathOr({}, ['result', 'entities', 'search']),
  ),
  [SEARCH_SUGGESTION_RESULTS_UPDATE]: mergeProduct(
    pathOr({}, ['payload', 'entities', 'search']),
  ),
  [GET_SIMILAR_PRODUCTS.success]: saveSimilarProducts(),
  [SAVED_PRODUCT_LIST_UPDATE]: updateSavedProductList(),
  [SAVED_PRODUCT_LIST_REMOVE]: removeSavedProduct(),
  [DISCARD_PRODUCT_LIST_UPDATE]: updateDiscardProductList(),
  [DISCARD_PRODUCT_LIST_REMOVE]: removeDiscardProduct(),
  [DISCARD_PRODUCT_LIST_ADDED]: addDiscardProduct(),

  MERGE_ENTITIES: (state, { payload: { entities } }) =>
    Object.keys(entities).reduce(
      // result = the new state we're constructing
      // key = each key of entities
      (result, key) => ({
        // merge each new or changed entity to the current state
        ...result,
        [key]: Object.keys(entities[key]).reduce(
          // items = the entity's mapping of item IDs and items
          // id = each item ID
          (items, id) => ({
            // merge each new or changed item into the current entity
            ...items,
            [id]: {
              // merge all properties of the new or changed item with the current one (if any)
              ...items[id],
              ...entities[key][id],
            },
          }),

          result[key] || {},
        ),
      }),

      state,
    ),

  REMOVE_ENTITY: (state, { payload: { entityKey } }) => {
    delete state[entityKey];

    return state;
  },

  REMOVE_ENTITY_ITEM: (state, { payload: { entityKey, objKey } }) => {
    const obj = state[entityKey];

    if (obj) {
      delete obj[objKey];
    }

    return {
      ...state,
      [entityKey]: Object.assign({}, obj),
    };
  },
});
