import { omit, pathOr, pipe, reject, isNil, anyPass, isEmpty } from 'ramda';

export const buildSearchKeyParams = (params, omitKeys = []) => {
  const OMIT_GENERATE_KEYS = ['limit', 'totalPages', 'totalItems', ...omitKeys];
  const omitted = omit(OMIT_GENERATE_KEYS, params);
  const sorted = Object.keys(omitted).sort((a, b) => a.localeCompare(b));
  return JSON.stringify(
    sorted.reduce(
      (acc, k) => ({
        ...acc,
        [k]: omitted[k],
      }),
      {},
    ),
  );
};

export const omitQueryParams = (params, omitKeys = []) => {
  const page = pathOr(1, ['page'], params);
  const rejectConditions = anyPass([isNil, isEmpty]);

  return pipe(
    reject(rejectConditions),
    omit([page === 1 ? 'page' : '', ...omitKeys]),
  )(params);
};
