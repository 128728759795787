export async function handleResponse(response) {
  if (response.ok) {
    return response.json();
  }
  if (response.status === 400) {
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error(`Network response was not ok. Status code: ${response.status}`);
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  const errorMessage = (error.response && error.response.data) || error.message || 'Unknown error occurred';
  console.error('API ERROR:', errorMessage);
  throw new Error(errorMessage);
}
