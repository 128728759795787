import {
  USER_LOAD_TYPES,
  USER_UPDATE_TYPES,
  USER_ADD_FAVORITE_TYPES,
  USER_REMOVE_FAVORITE_TYPES,
  USER_UPDATE_SUBSCRIPTION_TYPES,
  USER_UPDATE_PAYMENT_TYPES,
  USER_GET_BILLING_HISTORY_TYPES,
  USER_ADD_SUBSCRIPTION_TYPES,
  USER_LOCAL_UPDATE_TYPES,
  USER_CANCEL_SUBSCRIPTION_TYPES,
  SHOPIFY_REQUEST_ACCESS_TOKEN_TYPES,
  INIT_SOCKET,
  USER_ADD_SUBSCRIPTION_SESSION_TYPES,
  USER_GET_SUBSCRIPTION_PORTAL_TYPES,
} from './actionTypes';
import {
  mergeEntities,
  getUserCollectionList,
  savedProductUpdate,
  savedProductRemove,
  discardProductRemove,
  discardProductAdded,
} from './application';
import { registerAnonymous } from './userAuth';
import { getFavouritesCollection } from 'common/helpers/select';

export const updateUserProfile = (payload) => ({
  types: USER_UPDATE_TYPES,
  promise: (api) => api.user.updateProfile(payload),
});

export const updateUserPassword = (payload) => ({
  types: USER_UPDATE_TYPES,
  promise: (api) => api.user.updatePassword(payload),
});

export const addToUserCollection = (id, payload) => (dispatch) => {
  return dispatch({
    types: USER_ADD_FAVORITE_TYPES,
    promise: (api) => api.user.addToCollection(id, payload),
  })
    .then((result) => {
      dispatch(savedProductUpdate(result.entities));
      dispatch(mergeEntities(result.entities)); // get result then merge entities result to entities reducer

      return result;
    })
    .catch(() => null);
};

export const removeFromUserCollection = (id, itemId) => (dispatch) => {
  return dispatch({
    types: USER_REMOVE_FAVORITE_TYPES,
    promise: (api) => api.user.removeFromCollection(id, itemId),
  })
    .then((result) => {
      dispatch(savedProductRemove(itemId));
      dispatch(mergeEntities(result.entities)); // get result then merge entities result to entities reducer

      return result;
    })
    .catch(() => null);
};

export function addToFavourites(payload) {
  return async function (dispatch, getState) {
    if (!getState().auth.token) {
      await dispatch(registerAnonymous());
    }

    await dispatch(getUserCollectionList());
    const collection = getFavouritesCollection(getState());
    if (!collection) {
      return Promise.resolve();
    }

    return dispatch(addToUserCollection(collection.id, payload));
  };
}

export function removeFromFavourites(itemId) {
  return async function (dispatch, getState) {
    if (!getState().auth.token) {
      return Promise.resolve();
    }

    await dispatch(getUserCollectionList());
    const collection = getFavouritesCollection(getState());
    if (!collection) {
      return Promise.resolve();
    }

    return dispatch(removeFromUserCollection(collection.id, itemId));
  };
}

//TODO: apply api to remove product to discard collection
export const removeFromDiscardCollection = (id, itemId) => (dispatch) => {
  return dispatch(discardProductRemove(itemId));
};

//TODO: apply api to add product to discard collection
export const addToDiscardCollection = (id, payload) => (dispatch) => {
  return dispatch(discardProductAdded(payload));
  // TODO: Do it after implement api
  // return dispatch({
  //   types: USER_ADD_DISCARD_TYPES,
  //   promise: () => {}
  // })
  //   .then(result => {
  //     dispatch(discardProductUpdate(result.entities));
  //     dispatch(mergeEntities(result.entities)); // get result then merge entities result to entities reducer
  //
  //     return result;
  //   })
  //   .catch(() => null);
};

export function addToDiscards(itemId) {
  return async function (dispatch, getState) {
    if (!getState().auth.token) {
      return Promise.resolve();
    }

    // TODO: Do it after implement api
    // await dispatch(getUserDiscardList());
    // const collection = getDiscardsCollection(getState());
    // if (!collection) {
    //   return Promise.resolve();
    // }

    return dispatch(addToDiscardCollection(null, itemId));
  };
}

export function removeFromDiscards(itemId) {
  return async function (dispatch, getState) {
    if (!getState().auth.token) {
      return Promise.resolve();
    }

    // TODO: Do it after implement api
    // await dispatch(getUserDiscardList());
    // const collection = getDiscardsCollection(getState());
    // if (!collection) {
    //   return Promise.resolve();
    // }
    return dispatch(removeFromDiscardCollection(null, itemId));
  };
}

export const updateLocalUser = (payload) => ({
  type: USER_LOCAL_UPDATE_TYPES.success,
  payload,
});

export const fetchUserSubscription = () => ({
  types: USER_LOAD_TYPES,
  promise: (api) => api.stripe.fetchSubscription(),
});

export const fetchUserBillingHistory = () => ({
  types: USER_GET_BILLING_HISTORY_TYPES,
  promise: (api) => api.stripe.getBillingHistory(),
});

export const beginSubscriptionAndPayment = () => ({
  type: USER_UPDATE_SUBSCRIPTION_TYPES.loading,
});

export const endSubscriptionAndPayment = () => ({
  type: USER_UPDATE_SUBSCRIPTION_TYPES.fail,
});

export const addUserSubscription = (payload) => (dispatch) => {
  return dispatch({
    types: USER_ADD_SUBSCRIPTION_TYPES,
    promise: (api) => api.stripe.addSubscription(payload),
  }).then((result) => {
    dispatch(updateLocalUser(result.entities.users[result.result]));

    return result;
  });
};

export const addSubscriptionSession = (payload) => (dispatch) => {
  return dispatch({
    types: USER_ADD_SUBSCRIPTION_SESSION_TYPES,
    promise: (api) => api.stripe.addSubscriptionSession(payload),
  }).then((result) => {
    // dispatch(updateLocalUser(result.entities.users[result.result]));

    return result;
  });
};

export const getSubscriptionPortal = () => (dispatch) => {
  return dispatch({
    types: USER_GET_SUBSCRIPTION_PORTAL_TYPES,
    promise: (api) => api.stripe.getSubscriptionPortal(),
  });
};

export const updateUserSubscription = (payload) => (dispatch) => {
  return dispatch({
    types: USER_UPDATE_SUBSCRIPTION_TYPES,
    promise: (api) => api.stripe.updateSubscription(payload),
  }).then((result) => {
    dispatch(updateLocalUser(result.entities.users[result.result]));

    return result;
  });
};

export const cancelUserSubscription = () => (dispatch) => {
  return dispatch({
    types: USER_CANCEL_SUBSCRIPTION_TYPES,
    promise: (api) => api.stripe.cancelSubscription(),
  }).then((result) => {
    dispatch(updateLocalUser(result.entities.users[result.result]));

    return result;
  });
};

export const updateUserPayment = (payload) => (dispatch) => {
  return dispatch({
    types: USER_UPDATE_PAYMENT_TYPES,
    promise: (api) => api.stripe.updatePayment(payload),
  }).then((result) => {
    dispatch(updateLocalUser(result.entities.users[result.result]));

    return result;
  });
};

export const shopifyRequestAccessToken = (params) => (dispatch) => {
  return dispatch({
    types: SHOPIFY_REQUEST_ACCESS_TOKEN_TYPES,
    promise: (api) => api.integrations.shopify.requestAccessToken(params),
  }).then((result) => {
    return result;
  });
};

export const initSocket = () => ({
  type: INIT_SOCKET.success,
});
