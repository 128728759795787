import {
  SEARCH_BAR_TYPES,
  NAV_BAR_TYPES,
  REGISTER_DIALOG_TYPES,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR
} from '../actions/actionTypes';

import createReducer from 'common/store/createReducer';

const initialState = {
  searchBarDisplay: true,
  navBarDisplay: false,
  registerDialogDisplay: false,
  notifications: []
};

export function enqueueSnackbar(state, action) {
  return {
    ...state,
    notifications: [
      ...state.notifications,
      {
        key: action.key,
        ...action.notification,
      },
    ],
  };
}

export function closeSnackbar(state, action) {
  return {
    ...state,
    notifications: state.notifications.map(notification => (
      (action.dismissAll || notification.key === action.key)
        ? { ...notification, dismissed: true }
        : { ...notification }
    )),
  };
}

export function removeSnackbar(state, action) {
  return {
    ...state,
    notifications: state.notifications.filter(
      notification => notification.key !== action.key,
    ),
  };
}

export default createReducer(initialState, {
  [ENQUEUE_SNACKBAR]: enqueueSnackbar,
  [CLOSE_SNACKBAR]: closeSnackbar,
  [REMOVE_SNACKBAR]: removeSnackbar,
  [SEARCH_BAR_TYPES.display]: (state, action) => {
    return {
      ...state,
      searchBarDisplay: action.display
    };
  },
  [NAV_BAR_TYPES.display]: (state, action) => {
    return {
      ...state,
      navBarDisplay: action.display
    };
  },
  [REGISTER_DIALOG_TYPES.display]: (state, action) => {
    return {
      ...state,
      registerDialogDisplay: action.display
    };
  }
});
