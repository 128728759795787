import React from 'react';

import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  isLoadingAddSubscriptionSession,
  isLoadingGetSubscriptionPortal,
  isLoadingSocialRegister,
  isRouting,
  isSearching,
  isSearchingDiscover,
} from 'common/store/selector/loading';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { or, pipe, reduce, values } from 'ramda';

const useStyles = makeStyles((theme) => ({
  linearProgress: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1200,
  },
}));

const isLoadingSelector = createStructuredSelector({
  isRouting,
  isSearching,
  isSearchingDiscover,
  isLoadingSocialRegister,
  isLoadingAddSubscriptionSession,
  isLoadingGetSubscriptionPortal,
});

const GlobalLoader = () => {
  const classes = useStyles();
  const loaderMapper = useSelector(isLoadingSelector);
  const isLoading = pipe(values, reduce(or, false))(loaderMapper);

  if (!isLoading) return null;

  return (
    <div className={classes.linearProgress}>
      <LinearProgress />
    </div>
  );
};

GlobalLoader.propTypes = {};

export default GlobalLoader;
