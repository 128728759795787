import { createTheme } from '@material-ui/core/styles';
import { colors } from 'common/constants';

const theme = createTheme({
  palette: {
    background: {
      default: 'rgb(242, 242, 242)'
    },
    primary: {
      light: colors.primaryLight,
      main: colors.primary,
      dark: colors.primaryDark
    },
    secondary: {
      light: colors.secondaryLight,
      main: colors.secondary,
      dark: colors.secondaryDark
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Roboto'].join(',')
  }
});

export default theme;
