import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import { defaultTo, evolve, pipe, propOr, map, identity } from 'ramda';

export function selectEntity(entityKey) {
  return state => {
    return state ? state.entities[entityKey] : {};
  };
}

export function selectFromProps(propName) {
  return (state, props) => props[propName];
}

export function selectOne(entityKey, id) {
  return typeof id === 'function'
    ? // id is a selector
      createSelector(selectEntity(entityKey), id, (entity, id) =>
        id ? entity[id] : null
      )
    : createSelector(
        selectEntity(entityKey),
        () => id,
        (entity, id) => (id ? entity[id] : null)
      );
}

export function selectMany(entityKey, ids) {
  return typeof ids === 'function'
    ? // ids is a selector
      createSelector(selectEntity(entityKey), ids, (entity, ids) =>
        ids ? ids.map(id => (id ? entity[id] : null)) : null
      )
    : createSelector(selectEntity(entityKey), entity =>
        ids ? ids.map(id => (id ? entity[id] : null)) : null
      );
}

export function selectOneIfNotProvided(entityKey, propName, idPropName) {
  return (state, props) => {
    const prop = props[propName];
    if (prop) return prop;

    const id = props[idPropName];
    if (!id) return undefined;

    return selectOne(entityKey, id)(state) || undefined;
  };
}

export function selectManyIfNotProvided(entityKey, propName, idsPropName) {
  return (state, props) => {
    const prop = props[propName];
    if (prop) return prop;

    const ids = props[idsPropName];
    if (!ids) return undefined;

    return selectMany(entityKey, ids)(state) || undefined;
  };
}

export function entityExists(entityKey, compareKey) {
  // eslint-disable-next-line no-unused-vars
  return (state, props) => {
    const obj = state.entities[entityKey];

    return obj && obj[compareKey];
  };
}

export const getSavedProducts = state => state.entities.savedProducts;
export const getMyCollections = state => state.entities.myCollections;

export const getFavouritesCollection = createSelector(
  [getMyCollections],
  collection =>
    collection &&
    Object.values(collection).find(item => item.code === 'favorites')
);

//TODO: change code to `discards` and remember to return discards into myCollections
export const getDiscardsCollection = createSelector(
  [getMyCollections],
  collection =>
    collection &&
    Object.values(collection).find(item => item.code === 'favorites')
);

export const getNoOfFavourites = createSelector(
  [getSavedProducts],
  favourites => favourites && Object.keys(favourites).length
);

export const getCurrentUser = createSelector(
  state => state.auth.user,
  currentUser => currentUser
);

export const getListProductSearched = createSelector(
  (state) => state.searchProduct,
  (state) => state.entities.products,
  (searchProduct, productEntities) =>
    evolve({
      list: pipe(
        defaultTo([]),
        map((id) => productEntities[id]),
      ),
      total: identity,
    })(searchProduct),
);

