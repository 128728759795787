import {
  GET_INTEGRATION_PAGES,
  GET_INTEGRATION_BY_ID,
  ADD_PRODUCT_INTEGRATION,
  REMOVE_PRODUCT_INTEGRATION,
  EDIT_PRODUCT_INTEGRATION,
  SELECT_PRODUCTS_INTEGRATION, UNSELECT_PRODUCTS_INTEGRATION, ADD_NEW_INTEGRATION,
} from './actionTypes';
import { mergeEntities } from './application';
import { path } from 'ramda';

export const getIntegrationPages = () => (dispatch) => {
  return dispatch({
    types: GET_INTEGRATION_PAGES,
    promise: (api) => api.integrations.fetchAll(),
  })
    .then((result) => {
      return result.entities.integrations;
    })
    .catch(() => null);
};

export const addNewIntegration = (payload) => (dispatch) => {
  return dispatch({
    types: ADD_NEW_INTEGRATION,
    promise: (api) => api.integrations.addIntegration(payload),
  })
    .then((res) => {
      return res.data;
    })
    .catch(() => null);
};

export const getIntegrationById = (id) => (dispatch) => {
  return dispatch({
    types: GET_INTEGRATION_BY_ID,
    promise: (api) => api.integrations.fetchOne(id),
  })
    .then((result) => {
      dispatch(mergeEntities({ products: result.entities.products }));
      return result.entities.integrations;
    })
    .catch(() => null);
};

export const addProductToIntegration = (payload) => (dispatch) => {
  return dispatch({
    types: ADD_PRODUCT_INTEGRATION,
    promise: (api) => api.integrations.addProduct(payload),
  })
    .then((result) => {
      dispatch(mergeEntities({ products: result.entities.products }));
      return result.entities.integrations;
    })
    .catch(() => null);
};

export const removeProductToIntegration = (integrationId, productId) => (
  dispatch,
) => {
  return dispatch({
    types: REMOVE_PRODUCT_INTEGRATION,
    promise: (api) => api.integrations.removeProduct(integrationId, productId),
  })
    .then((res) => {
      const integrationCode = path(['entities', 'integrations', res.result, 'code'], res);
      dispatch(unselectProductsIntegration(integrationCode, [productId]));
      dispatch(mergeEntities({ products: res.entities.products }));
      return res.entities.integrations;
    })
    .catch(() => null);
};

export const editProductIntegration = (integrationId, data) => (dispatch) => {
  return dispatch({
    types: EDIT_PRODUCT_INTEGRATION,
    promise: (api) => api.integrations.editProduct(integrationId, data),
  })
    .then((result) => {
      dispatch(mergeEntities({ products: result.entities.products }));
      return result.entities.integrations;
    })
    .catch(() => null);
};

export const selectProductsIntegration = (integrationName, productIds) => ({
  type: SELECT_PRODUCTS_INTEGRATION.success,
  payload: {
    integrationName,
    productIds,
  }
});

export const unselectProductsIntegration = (integrationName, productIds) => ({
  type: UNSELECT_PRODUCTS_INTEGRATION.success,
  payload: {
    integrationName,
    productIds,
  }
});