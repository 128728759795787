import {
  CATEGORY_TYPES,
  PRODUCT_TYPES,
  COLLECTION_TYPES,
  EMAIL_TYPES,
  SEARCH_TYPES,
  STRIPE_TYPES,
  LOAD_MORE_SEARCH_LIST_TYPES,
  DISCOVER_TYPES,
  ROUTING,
  SAVED_PRODUCT_LIST_UPDATE,
  SAVED_PRODUCT_LIST_REMOVE,
  DISCARD_PRODUCT_LIST_UPDATE,
  DISCARD_PRODUCT_LIST_REMOVE,
  DISCARD_PRODUCT_LIST_ADDED,
  GET_PRODUCT_INTEGRATIONS,
  GET_SIMILAR_PRODUCTS,
  SET_VERSION_NUMBER,
} from './actionTypes';
import { omitQueryParams } from 'common/helpers/searchPaginate';
import {
  curry,
  defaultTo,
  either,
  isEmpty,
  isNil,
  lensProp,
  mergeLeft,
  omit,
  over,
  pick,
  pipe,
  reject,
} from 'ramda';
import { discoverListUpdate } from './discover';
import { LIMIT_DEFAULT } from '../../constants';

export const MERGE_ENTITIES = 'MERGE_ENTITIES';
export const REMOVE_ENTITY = 'REMOVE_ENTITY';
export const REMOVE_ENTITY_ITEM = 'REMOVE_ENTITY_ITEM';

export const routeChangeStart = () => ({
  type: ROUTING.loading,
});

export const routeChangeComplete = () => ({
  type: ROUTING.success,
});

export const routeChangeError = () => ({
  type: ROUTING.failure,
});

export const mergeEntities = (entities) => ({
  type: MERGE_ENTITIES,
  payload: { entities },
});

export const removeEntity = (entityKey) => ({
  type: REMOVE_ENTITY,
  payload: { entityKey },
});

export const removeEntityItem = (entityKey, objKey) => ({
  type: REMOVE_ENTITY_ITEM,
  payload: { entityKey, objKey },
});

export const getProduct = (id) => (dispatch) => {
  return dispatch({
    types: PRODUCT_TYPES,
    promise: (api) => api.products.fetchOne(id),
  })
    .then((result) => {
      dispatch(mergeEntities(result.entities));

      return result;
    })
    .catch(() => null);
};

export const getProductIntegrations = (id) => (dispatch) => {
  return dispatch({
    types: GET_PRODUCT_INTEGRATIONS,
    promise: (api) => api.products.fetchOneIntegrations(id),
  })
    .then((result) => {
      return result.entities.integrations;
    })
    .catch(() => null);
};

export const getSimilarProducts = (data) => (dispatch) => {
  return dispatch({
    types: GET_SIMILAR_PRODUCTS,
    promise: (api) => api.products.fetchSimilar(data),
  })
    .then((result) => {
      return result.entities;
    })
    .catch(() => null);
};

export const getCategoryList = () => (dispatch) => {
  return dispatch({
    types: CATEGORY_TYPES,
    promise: (api) => api.categories.fetchAll(),
  })
    .then((result) => {
      dispatch(mergeEntities(result.entities));

      return result;
    })
    .catch(() => null);
};

export const getCollection = (id) => (dispatch) => {
  return dispatch({
    types: COLLECTION_TYPES,
    promise: (api) => api.collections.fetchOne(id),
  })
    .then((result) => {
      dispatch(mergeEntities(result.entities));

      return result;
    })
    .catch(() => null);
};

export const getCollectionList = () => (dispatch) => {
  return dispatch({
    types: COLLECTION_TYPES,
    promise: (api) => api.collections.fetchAll(),
  })
    .then((result) => {
      dispatch(mergeEntities(result.entities));
      dispatch(discoverListUpdate(result.result.list));
      return result;
    })
    .catch(() => null);
};

export const getDiscoverSearch = (filters) => (dispatch) => {
  const queryParams = omitQueryParams(filters, ['page']);

  return dispatch({
    types: DISCOVER_TYPES,
    promise: (api) => api.discover.fetchAll(filters),
  }).then((result) => {
    dispatch(mergeEntities(result.entities));
    dispatch(discoverListUpdate(result.result.list));
    return { queryParams };
  });
};

const fetchSearchList = curry((actionType, params, dispatch) => {
  const queryParams = pipe(
    pick(['type', 'offset', 'limit', 'q']),
    over(lensProp('limit'), defaultTo(LIMIT_DEFAULT)),
    over(lensProp('offset'), defaultTo(0)),
  )(params);
  const dataParams = pipe(
    omit(['type', 'offset', 'limit', 'q']),
    reject(either(isNil, isEmpty)),
  )(params);

  return dispatch({
    types: actionType,
    promise: (api) =>
      api.elastic.search(queryParams, dataParams).then(
        mergeLeft({
          params,
          queryParams,
          dataParams,
        }),
      ),
  }).then(() => {
    return {
      params,
      queryParams,
      dataParams,
    };
  });
});

export const getSearchList = fetchSearchList(SEARCH_TYPES);
export const loadMoreSearchList = fetchSearchList(LOAD_MORE_SEARCH_LIST_TYPES);

export const savedProductUpdate = (entities) => ({
  type: SAVED_PRODUCT_LIST_UPDATE,
  payload: { entities },
});

export const savedProductRemove = (id) => ({
  type: SAVED_PRODUCT_LIST_REMOVE,
  payload: { id },
});

export const discardProductUpdate = (entities) => ({
  type: DISCARD_PRODUCT_LIST_UPDATE,
  payload: { entities },
});

export const discardProductRemove = (id) => ({
  type: DISCARD_PRODUCT_LIST_REMOVE,
  payload: { id },
});

export const discardProductAdded = (id) => ({
  type: DISCARD_PRODUCT_LIST_ADDED,
  payload: { id },
});

export const getUserCollectionList = () => (dispatch) => {
  return dispatch({
    types: COLLECTION_TYPES,
    promise: (api) => api.user.fetchCollections(),
  })
    .then((result) => {
      dispatch(savedProductUpdate(result.entities));
      dispatch(mergeEntities(result.entities)); // get result then merge entities result to entities reducer

      return result;
    })
    .catch(() => null);
};

//TODO: Change api to get discard list like with data structure same fetchCollections
export const getUserDiscardList = () => (dispatch) => {
  return dispatch({
    types: COLLECTION_TYPES,
    promise: (api) => api.user.fetchDiscardCollections(),
  })
    .then((result) => {
      dispatch(discardProductUpdate(result.entities));
      dispatch(mergeEntities(result.entities)); // get result then merge entities result to entities reducer

      return result;
    })
    .catch(() => null);
};

export const emailSubscriber = (payload) => ({
  types: EMAIL_TYPES,
  promise: (api) => api.email.subscriber(payload),
});

// eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef,no-undef
export const loadStripeKey = (stripeKey = stripeSecretKey) => ({
  type: STRIPE_TYPES.loading,
  payload: stripeKey,
});

export const initStripe = (instance) => ({
  type: STRIPE_TYPES.success,
  payload: instance,
});

export const setupStripeInstance = () => (dispatch) => {
  const { payload: stripeKey } = dispatch(loadStripeKey());

  const instance = window.Stripe(stripeKey);

  dispatch(initStripe(instance));

  return instance;
};

export const setVersionNumber = (value) => ({
  type: SET_VERSION_NUMBER.success,
  payload: { value },
});
