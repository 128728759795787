import {
  USER_REGISTER_TYPES,
  USER_SOCIAL_REGISTER_TYPES,
  USER_REGISTER_ANONYMOUS_TYPES,
  USER_LOGIN_TYPES,
  USER_LOGOUT_TYPES,
  USER_SET_TOKEN_TYPES,
  USER_LOAD_TYPES,
  USER_UPDATE_TYPES,
  USER_RESET_PASSWORD_REQUEST_TYPES,
  USER_RESET_PASSWORD_TYPES, 
  USER_LOCAL_UPDATE_TYPES,
  SHOPIFY_REQUEST_ACCESS_TOKEN_TYPES,
} from '../actions/actionTypes';
import createReducer from 'common/store/createReducer';
import { setCookie, removeCookie } from 'common/utils/cookie';
import { lensProp, mergeLeft, over, propOr } from 'ramda';

const initialState = {
  token: null,
  user: null,
};

const getUser = (data) => (data && data.entities.users[data.result]) || null;

export default createReducer(initialState, {
  [USER_REGISTER_TYPES.success]: (state, action) => {
    const {
      result,
      entities: { users },
    } = action.result;

    const authInfo = users[result].auth;
    if (authInfo) {
      setCookie('token', authInfo.token);
    }

    return {
      ...state,
      token: authInfo.token,
      user: users[result],
    };
  },
  [USER_SOCIAL_REGISTER_TYPES.success]: (state, action) => {
    const {
      result,
      entities: { users },
    } = action.result;

    const authInfo = users[result].auth;
    if (authInfo) {
      setCookie('token', authInfo.token);
    }

    return {
      ...state,
      token: authInfo.token,
      user: users[result],
    };
  },
  [USER_REGISTER_ANONYMOUS_TYPES.success]: (state, action) => {
    const {
      result,
      entities: { users },
    } = action.result;

    const authInfo = users[result].auth;
    if (authInfo) {
      setCookie('token', authInfo.token);
    }

    return {
      ...state,
      token: authInfo.token,
      user: users[result],
    };
  },
  [USER_LOGIN_TYPES.success]: (state, action) => {
    const {
      result,
      entities: { users },
    } = action.result;

    const authInfo = users[result].auth;
    if (authInfo) {
      setCookie('token', authInfo.token);
    }

    return {
      ...state,
      token: authInfo.token,
      user: users[result],
    };
  },
  [USER_LOGOUT_TYPES.success]: (state) => {
    removeCookie('token');

    return {
      ...state,
      token: null,
      user: null,
    };
  },
  [USER_SET_TOKEN_TYPES.success]: (state, { payload: { token } }) => {
    return {
      ...state,
      token: token,
    };
  },
  [USER_LOAD_TYPES.success]: (state, { result }) => {
    return {
      ...state,
      user: getUser(result),
    };
  },
  [USER_UPDATE_TYPES.success]: (state, { result }) => {
    return {
      ...state,
      user: getUser(result),
    };
  },
  [USER_LOCAL_UPDATE_TYPES.success]: (state, { payload }) => {
    return {
      ...state,
      user: payload,
    };
  },
  [USER_RESET_PASSWORD_REQUEST_TYPES.success]: (state) => {
    return {
      ...state,
      sending: false,
    };
  },

  [USER_RESET_PASSWORD_TYPES.success]: (state) => {
    return {
      ...state,
      resetting: false,
    };
  },

  [SHOPIFY_REQUEST_ACCESS_TOKEN_TYPES.success]: (state, { result }) => {
    const data = propOr({}, 'data', result);
    return over(lensProp('user'), mergeLeft(data), state);
  },
});