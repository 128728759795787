import { createAction } from 'redux-actions';
import {
  SEARCH_SUGGESTION_START,
  SEARCH_SUGGESTION_REQUEST_FAIL,
  SEARCH_SUGGESTION_STOP,
  SEARCH_SUGGESTION_RESULTS_UPDATE,
  SEARCH_SUGGESTION_RESULTS_HIDE,
  SEARCH_SUGGESTION_RESULTS_SHOW,
  SEARCH_SUGGESTION_RESULTS_CLEAR,
  SEARCH_SUGGESTION_QUERY_UPDATE,
  SEARCH_TYPES
} from './actionTypes';

export const SEARCH_SUGGESTION_LIMIT = 8;

// Trigger saga
export function startSearchSuggestion(keyword) {
  return {
    type: SEARCH_SUGGESTION_START,
    payload: {
      keyword,
      limit: SEARCH_SUGGESTION_LIMIT
    }
  };
}

export const searchProductSuccess = createAction(SEARCH_TYPES.success);

export const failedToFetchSearchResults = createAction(
  SEARCH_SUGGESTION_REQUEST_FAIL
);

export const stopSearchSuggestion = createAction(SEARCH_SUGGESTION_STOP);

export const hideSearchSuggestionResults = createAction(
  SEARCH_SUGGESTION_RESULTS_HIDE
);

export const showSearchSuggestionResults = createAction(
  SEARCH_SUGGESTION_RESULTS_SHOW
);

export const clearSearchSuggestionResults = createAction(
  SEARCH_SUGGESTION_RESULTS_CLEAR
);

export function updateSearchResults(entities) {
  return {
    type: SEARCH_SUGGESTION_RESULTS_UPDATE,
    payload: {
      entities
    }
  };
}

export function updateSearchSuggestionQuery(query) {
  return {
    type: SEARCH_SUGGESTION_QUERY_UPDATE,
    payload: { query }
  };
}

export function clearAndHideSearchResults() {
  return dispatch => {
    dispatch(hideSearchSuggestionResults());
    dispatch(clearSearchSuggestionResults());
  };
}
