export const makeActionType = (name) => `${NAMESPACE}_${name}`;

const getActionTypes = (name) => {
  if (!name) throw 'name must not null';

  return {
    original: makeActionType(name),
    loading: `${makeActionType(name)}_LOADING`,
    success: `${makeActionType(name)}_SUCCESS`,
    failure: `${makeActionType(name)}_FAILURE`,
    display: `${makeActionType(name)}_DISPLAY`,
  };
};

export const NAMESPACE = 'APP';

/**
 * USER AUTH action constants
 */

export const USER_LOGIN_TYPES = getActionTypes('USER_LOGIN');

export const USER_REGISTER_TYPES = getActionTypes('USER_REGISTER');

export const USER_REGISTER_ANONYMOUS_TYPES = getActionTypes(
  'USER_REGISTER_ANONYMOUS',
);

export const USER_SOCIAL_REGISTER_TYPES = getActionTypes(
  'USER_SOCIAL_REGISTER',
);

export const USER_RESET_PASSWORD_TYPES = getActionTypes('USER_RESET_PASSWORD');

export const USER_RESET_PASSWORD_REQUEST_TYPES = getActionTypes(
  'USER_RESET_PASSWORD_REQUEST',
);

export const USER_LOGOUT_TYPES = getActionTypes('USER_LOGOUT');

export const USER_SET_ANONYMOUS_TYPES = getActionTypes('USER_SET_ANONYMOUS');

export const USER_LOAD_TYPES = getActionTypes('USER_LOAD');

export const USER_SET_TOKEN_TYPES = getActionTypes('USER_SET_TOKEN');

/**
 * USER action constants
 */

export const USER_COLLECTION_TYPES = getActionTypes('USER_COLLECTION');

export const USER_UPDATE_TYPES = getActionTypes('USER_UPDATE');

export const USER_LOCAL_UPDATE_TYPES = getActionTypes('USER_LOCAL_UPDATE');

export const USER_ADD_FAVORITE_TYPES = getActionTypes('USER_ADD_FAVORITE');

export const USER_REMOVE_FAVORITE_TYPES = getActionTypes(
  'USER_REMOVE_FAVORITE',
);

export const USER_ADD_DISCARD_TYPES = getActionTypes('USER_ADD_DISCARD');

export const USER_REMOVE_DISCARD_TYPES = getActionTypes('USER_REMOVE_DISCARD');

export const USER_UPDATE_SUBSCRIPTION_TYPES = getActionTypes(
  'USER_UPDATE_SUBSCRIPTION',
);

export const USER_ADD_SUBSCRIPTION_TYPES = getActionTypes(
  'USER_ADD_SUBSCRIPTION',
);

export const USER_ADD_SUBSCRIPTION_SESSION_TYPES = getActionTypes(
  'USER_ADD_SUBSCRIPTION_SESSION',
);

export const USER_GET_SUBSCRIPTION_PORTAL_TYPES = getActionTypes(
  'USER_GET_SUBSCRIPTION_PORTAL',
);

export const USER_CANCEL_SUBSCRIPTION_TYPES = getActionTypes(
  'USER_CANCEL_SUBSCRIPTION',
);

export const USER_UPDATE_PAYMENT_TYPES = getActionTypes('USER_UPDATE_PAYMENT');

export const SHOPIFY_REQUEST_ACCESS_TOKEN_TYPES = getActionTypes('SHOPIFY_REQUEST_ACCESS_TOKEN');

export const USER_GET_BILLING_HISTORY_TYPES = getActionTypes(
  'USER_GET_BILLING_HISTORY',
);

/**
 * APPLICATION action constants
 */

export const NAV_BAR_TYPES = getActionTypes('NAV_BAR');

export const REGISTER_DIALOG_TYPES = getActionTypes('REGISTER_DIALOG');

export const CATEGORY_TYPES = getActionTypes('CATEGORY');

export const PRODUCT_TYPES = getActionTypes('PRODUCT');

export const COLLECTION_TYPES = getActionTypes('COLLECTION');

export const DISCOVER_TYPES = getActionTypes('DISCOVER');

export const SEARCH_TYPES = getActionTypes('SEARCH');

export const LOAD_MORE_SEARCH_LIST_TYPES = getActionTypes(
  'LOAD_MORE_SEARCH_LIST_TYPES',
);

export const EMAIL_TYPES = getActionTypes('EMAIL');

export const STRIPE_TYPES = getActionTypes('STRIPE');

export const ROUTING = getActionTypes('ROUTING');

export const SET_VERSION_NUMBER = getActionTypes('SET_VERSION_NUMBER');

/**
 * SEARCH action constants
 */

export const SEARCH_SUGGESTION_START = 'SEARCH_SUGGESTION_START';

export const SEARCH_SUGGESTION_REQUEST_FAIL = 'SEARCH_SUGGESTION_REQUEST_FAIL';

export const SEARCH_SUGGESTION_STOP = 'SEARCH_SUGGESTION_STOP';

export const SEARCH_SUGGESTION_RESULTS_UPDATE =
  'SEARCH_SUGGESTION_RESULTS_UPDATE';

export const SEARCH_SUGGESTION_RESULTS_HIDE = 'SEARCH_SUGGESTION_RESULTS_HIDE';

export const SEARCH_SUGGESTION_RESULTS_SHOW = 'SEARCH_SUGGESTION_RESULTS_SHOW';

export const SEARCH_SUGGESTION_RESULTS_CLEAR =
  'SEARCH_SUGGESTION_RESULTS_CLEAR';

export const SEARCH_SUGGESTION_QUERY_UPDATE = 'SEARCH_SUGGESTION_QUERY_UPDATE';

/**
 * UI action constants
 */

export const OPEN_LEFT_NAV_TYPES = getActionTypes('OPEN_LEFT_NAV');

export const CLOSE_LEFT_NAV_TYPES = getActionTypes('CLOSE_LEFT_NAV');

export const CLEAR_UI_TYPES = getActionTypes('CLEAR_UI');

export const SHOW_MODAL_TYPES = getActionTypes('SHOW_MODAL');

export const CLOSE_MODAL_TYPES = getActionTypes('CLOSE_MODAL');

export const TOGGLE_MOBILE_NAV_TYPES = getActionTypes('TOGGLE_MOBILE_NAV');

export const TOGGLE_SEARCH_FILTER_TYPES = getActionTypes(
  'TOGGLE_SEARCH_FILTER',
);

export const SUCCESS_SNACKBAR_TYPES = getActionTypes('SUCCESS_SNACKBAR');

export const CLEAR_SNACKBAR_TYPES = getActionTypes('CLEAR_SNACKBAR');

export const SEARCH_BAR_TYPES = getActionTypes('SEARCH_BAR');

export const SNACKBAR_TYPES = getActionTypes('SNACKBAR');

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export default getActionTypes;

/**
 * DISCOVER action constants
 */

export const DISCOVER_LIST_UPDATE = 'DISCOVER_LIST_UPDATE';

/**
 * FAVORITE action constants
 */

export const SAVED_PRODUCT_LIST_UPDATE = 'SAVED_PRODUCT_LIST_UPDATE';
export const SAVED_PRODUCT_LIST_REMOVE = 'SAVED_PRODUCT_LIST_REMOVE';

export const DISCARD_PRODUCT_LIST_UPDATE = 'DISCARD_PRODUCT_LIST_UPDATE';
export const DISCARD_PRODUCT_LIST_REMOVE = 'DISCARD_PRODUCT_LIST_REMOVE';
export const DISCARD_PRODUCT_LIST_ADDED = 'DISCARD_PRODUCT_LIST_ADDED';

export const GET_NOTIFICATIONS = getActionTypes(
  'GET_NOTIFICATIONS',
);

export const INIT_SOCKET = getActionTypes(
  'INIT_SOCKET',
);

/**
 * INTEGRATIONS action constants
 */
export const GET_INTEGRATION_PAGES = getActionTypes('GET_INTEGRATION_PAGES');
export const GET_INTEGRATION_BY_ID = getActionTypes('GET_INTEGRATION_BY_ID');

export const ADD_NEW_INTEGRATION = getActionTypes(
  'ADD_NEW_INTEGRATION',
);

export const ADD_PRODUCT_INTEGRATION = getActionTypes(
  'ADD_PRODUCT_INTEGRATION',
);
export const REMOVE_PRODUCT_INTEGRATION = getActionTypes(
  'REMOVE_PRODUCT_INTEGRATION',
);
export const EDIT_PRODUCT_INTEGRATION = getActionTypes(
  'EDIT_PRODUCT_INTEGRATION',
);
export const SELECT_PRODUCTS_INTEGRATION = getActionTypes(
  'SELECT_PRODUCT_INTEGRATION',
);
export const UNSELECT_PRODUCTS_INTEGRATION = getActionTypes(
  'UNSELECT_PRODUCT_INTEGRATION',
);

/**
 * PRODUCT action constants
 */

export const GET_PRODUCT_INTEGRATIONS = getActionTypes(
  'GET_PRODUCT_INTEGRATIONS',
);
export const GET_SIMILAR_PRODUCTS = getActionTypes('GET_SIMILAR_PRODUCTS');
