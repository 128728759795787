import createReducer from '../createReducer';
import { GET_NOTIFICATIONS } from '../actions/actionTypes';
import { lensProp, mergeLeft, over, pathOr } from 'ramda';

const initialState = {
  entities: {}
};

export function getNotificationList(state, { result }) {
  const entities = pathOr([], ['list'], result);
  return over(lensProp('entities'), mergeLeft(entities), state);
}

export default createReducer(initialState, {
  [GET_NOTIFICATIONS.success]: getNotificationList,
});
