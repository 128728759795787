import { prop, flatten } from 'ramda';
import { createSelector } from 'reselect';
import {
  ROUTING,
  SEARCH_TYPES,
  COLLECTION_TYPES,
  DISCOVER_TYPES,
  GET_INTEGRATION_PAGES,
  GET_INTEGRATION_BY_ID,
  USER_SOCIAL_REGISTER_TYPES,
  USER_ADD_SUBSCRIPTION_SESSION_TYPES,
  USER_GET_SUBSCRIPTION_PORTAL_TYPES,
} from '../actions/actionTypes';

export const getLoadingState = (state) => state.loading;

export const createLoadingSelector = (actions) =>
  createSelector(getLoadingState, (loadingState) =>
    flatten([actions]).some((action) => prop(action, loadingState)),
  );

export const isRouting = createLoadingSelector(ROUTING.original);

export const isSearching = createLoadingSelector(SEARCH_TYPES.original);

export const isSearchingDiscover = createLoadingSelector([
  COLLECTION_TYPES.original,
  DISCOVER_TYPES.original,
]);

export const isLoadingIntegration = createLoadingSelector([
  GET_INTEGRATION_PAGES.original,
  GET_INTEGRATION_BY_ID.original,
]);

export const isLoadingSocialRegister = createLoadingSelector([
  USER_SOCIAL_REGISTER_TYPES.original,
]);

export const isLoadingAddSubscriptionSession = createLoadingSelector([
  USER_ADD_SUBSCRIPTION_SESSION_TYPES.original,
]);

export const isLoadingGetSubscriptionPortal = createLoadingSelector([
  USER_GET_SUBSCRIPTION_PORTAL_TYPES.original,
]);
