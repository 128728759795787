import { put, call, takeLatest } from 'redux-saga/effects';
import { SEARCH_SUGGESTION_START } from '../actions/actionTypes';
import {
  failedToFetchSearchResults,
  stopSearchSuggestion,
  clearAndHideSearchResults,
  updateSearchResults,
  updateSearchSuggestionQuery,
  showSearchSuggestionResults
} from '../actions';
import api from 'common/api';

// Take in action
// Should add try catch
// Side effects in saga making actions cleaner. (Not mixing with thunks)
export function* doSearchSuggestion({ payload }) {
  const { keyword } = payload;

  try {
    if (!keyword || keyword.trim() === '') {
      yield put(clearAndHideSearchResults());
      return;
    }
    const finalKeyword = keyword.trim();
    yield put(updateSearchSuggestionQuery(finalKeyword));

    const searchResults = yield call(() => api.elastic.search({
      q: finalKeyword,
      type: 'product'
    }, {}));

    yield put(updateSearchResults(searchResults.entities));
    yield put(showSearchSuggestionResults());
  } catch (err) {
    console.error(err);
    yield put(failedToFetchSearchResults());
  } finally {
    yield put(stopSearchSuggestion());
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

export function* getSearchSuggestionSaga() {
  yield takeLatest(SEARCH_SUGGESTION_START, doSearchSuggestion);
}
