import { combineReducers } from 'redux';
import app from './application';
import entities from './entities';
import searchSuggestion from './searchSuggestion';
import searchProduct from './searchProduct';
import auth from './auth';
import ui from './ui';
import discover from './discover';
import loading from './loading';
import integration from './integration';
import billing from './billing';
import notification from './notification';

export default combineReducers({
  app,
  auth,
  searchSuggestion,
  searchProduct,
  entities,
  ui,
  discover,
  loading,
  integration,
  billing,
  notification
});
