import React from 'react';
import Image from 'next/image';

import whatsappIconImg from 'public/static/images/icons/whatsapp.svg';
import whatsappIconColorImg from 'public/static/images/icons/whatsapp-color.svg';
import shopifyIconImg from 'public/static/images/icons/shopify.svg';
import shopifyIconColorImg from 'public/static/images/icons/shopify-color.svg';
import magentoIconImg from 'public/static/images/icons/magento.svg';
import magentoIconColorImg from 'public/static/images/icons/magento-color.svg';
import instagramIconImg from 'public/static/images/icons/instagram.svg';
import instagramIconColorImg from 'public/static/images/icons/instagram-color.svg';

export const colors = {
  primary: '#15549c',
  primaryLight: '#1874bf',
  primaryDark: '#0e3e76',
  
  secondary: '#169296',
  secondaryLight: '#15a7ae',
  secondaryDark: '#157d7f',
  
  userMenu: '#C7DAD3',
  userMenuDark: '#648796',

  black: '#3b4144',
  white: '#FFFFFF',
  red: '#C6292E',
  green: '#205814',

  bannerText: '#0B245B',
  border: '#DADCE0',
  borderSecondary: '#f7f7f7',
  footer: '#F5F5F5',
  
  snackbarInfo: '#2979ff',
  snackbarSuccess: '#43a047',
  snackbarError: '#d32f2f',
  snackbarWarning: '#ffa000',
};

export const showOnlyList = ['New items', 'Items in USA'];

export const LIMIT_DEFAULT = 20;

export const trendingSearchList = [
  'Women\'s clothing',
  'Men\'s clothing',
  'Handbags',
  'Wallets',
];

export const departmentList = [
  {
    name: 'Home',
    id: 'home',
    children: [
      {
        name: 'Bedding',
        id: 'bedding',
      },
      {
        name: 'Bath',
        id: 'bath',
      },
      {
        name: 'Wall Art',
        id: 'wall_art',
      }
    ]
  },

  {
    name: 'Electronics',
    id: 'electronics',
    children: [
      {
        name: 'Camera & Photo',
        id: 'camera_photo',
      },
      {
        name: 'GPS & Navigation',
        id: 'gps_navigation',
      },
      {
        name: 'Headphones',
        id: 'headphones',
      },
      {
        name: 'Office Electronics',
        id: 'office_electronics',
      }
    ]
  },


  {
    name: 'Fashion',
    id: 'fashion',
    children: [
      {
        name: 'Clothing',
        id: 'clothing',
      },
      {
        name: 'Shoes',
        id: 'shoes',
      },
    ]
  },

  {
    name: 'Beauty',
    id: 'beauty',
    children: [
      {
        name: 'Makeup',
        id: 'makeup',
      },
      {
        name: 'SKin Care',
        id: 'skin_care',
      },
      {
        name: 'Lipstick',
        id: 'lipstick',
      }
    ]
  },


  {
    name: 'Personal Care',
    id: 'personal_care',
    children: [
      {
        name: 'Fragrance',
        id: 'fragrance',
      },
    ]
  },

  {
    name: 'Crafts & Gifts',
    id: 'Crafts_gifts',
  },

  {
    name: 'Adult',
    id: 'adult',
    children: [
      {
        name: 'Gifts',
        id: 'gifts',
      },
    ]
  },
];

export const categoryList = [
  'Women\'s clothing',
  'Men\'s clothing',
  'Handbags',
  'Wallets',
];

export const priceList = [
  'Up to $15',
  '$15 - $35',
  '$35 - $50',
  '$50 - $80',
  'Over $80',
];

export const sortList = [
  {
    label: 'Best match',
    value: 'dropseekerRecommended',
  },
  {
    label: 'Newly added',
    value: 'newlyAdded',
  },
  {
    label: 'Highest price first',
    value: 'priceHighToLow',
  },
  {
    label: 'Lowest price first',
    value: 'priceLowToHigh',
  },
];

export const planList = [
  {
    key: 0,
    planID: 'price_1H399fEc1F8Yz5UfncQnUoOX',
    planName: 'Basic',
    price: '28',
    duration: 'Month',
    billed: 'Monthly',
  },
  {
    key: 1,
    planID: 'price_1I31QoEc1F8Yz5UfgrP4bIuX',
    planName: 'Premium',
    price: '46',
    duration: 'Month',
    billed: 'Monthly',
  },
  {
    key: 2,
    planID: 'price_1I31QoEc1F8Yz5Ufg5ZvnKsU',
    planName: 'Basic Yearly',
    price: '220',
    duration: 'Year',
    billed: 'Yearly',
  },
  {
    key: 3,
    planID: 'price_1I31QoEc1F8Yz5UfG4pIphDe',
    planName: 'Premium Yearly',
    price: '340',
    duration: 'Year',
    billed: 'Yearly',
  },
];

export const newPlanList = [
  {
    key: 0,
    planID: 'price_1H399fEc1F8Yz5UfncQnUoOX',
    planName: 'Basic',
    price: '28',
    duration: 'Month',
    billed: 'Monthly',
    savedPercent: 0,
    beforeDiscounted: '28',
    description: '$28 billed every month',
    isPopular: false,
  },
  {
    key: 1,
    planID: 'price_1I31QoEc1F8Yz5UfgrP4bIuX',
    planName: 'Premium',
    price: '46',
    duration: 'Month',
    billed: 'Monthly',
    savedPercent: 0,
    beforeDiscounted: '46',
    description: '$46 billed every month',
    isPopular: true,
  },
  {
    key: 2,
    planID: 'price_1I31QoEc1F8Yz5Ufg5ZvnKsU',
    planName: 'Basic Yearly',
    price: '220',
    duration: 'Year',
    billed: 'Yearly',
    savedPercent: 35,
    beforeDiscounted: '336',
    description: '$220 billed for the first year',
    isPopular: false,
  },
  {
    key: 3,
    planID: 'price_1I31QoEc1F8Yz5UfG4pIphDe',
    planName: 'Premium Yearly',
    price: '340',
    duration: 'Year',
    billed: 'Yearly',
    savedPercent: 38,
    beforeDiscounted: '552',
    description: '$340 billed for the first year',
    isPopular: true,
  },
];

export const planBenefits = [
  { id: 1, name: 'See all new material', basic: true, premium: true },
  {
    id: 2,
    name: 'Fast order material',
    basic: true,
    premium: true,
  },
  {
    id: 3,
    name: 'Create one integration',
    basic: true,
    premium: true,
  },
  {
    id: 4,
    name: 'Search the material quickly',
    basic: true,
    premium: true,
  },
  {
    id: 5,
    name: 'See the discover list',
    basic: true,
    premium: true,
  },
  {
    id: 6,
    name: 'Create favourite list',
    basic: false,
    premium: true,
  },
  {
    id: 7,
    name: 'Create unlimited integration',
    basic: false,
    premium: true,
  },
];

export const integrationCode = {
  Shopify: 'shopify',
  Whatsapp: 'whatsapp',
  Magento: 'magento',
  Instagram: 'instagram'
};

export const integrationData = [
  {
    id: 1,
    name: 'Whatsapp',
    code: integrationCode.Whatsapp,
    icon: <Image className="icon" src={whatsappIconImg} fill />,
    iconColor: <Image className="icon" src={whatsappIconColorImg} fill />,
    description: 'Setup your Whatsapp integration',
  },
  {
    id: 2,
    name: 'Shopify',
    code: integrationCode.Shopify,
    icon: <Image className="icon" src={shopifyIconImg} fill />,
    iconColor: <Image className="icon" src={shopifyIconColorImg} fill />,
    description: 'Setup your Shopify integration',
  },
  {
    id: 3,
    name: 'Magento',
    code: integrationCode.Magento,
    icon: <Image className="icon" src={magentoIconImg} fill />,
    iconColor: <Image className="icon" src={magentoIconColorImg} fill />,
    description: 'Setup your Magento integration',
  },
  {
    id: 4,
    name: 'Instagram',
    code: integrationCode.Instagram,
    icon: <Image className="icon" src={instagramIconImg} fill />,
    iconColor: <Image className="icon" src={instagramIconColorImg} fill />,
    description: 'Setup your Instagram integration',
  },
];
