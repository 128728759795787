import createReducer from '../createReducer';
import {
  GET_INTEGRATION_PAGES,
  GET_INTEGRATION_BY_ID,
  ADD_PRODUCT_INTEGRATION,
  REMOVE_PRODUCT_INTEGRATION,
  SELECT_PRODUCTS_INTEGRATION,
  UNSELECT_PRODUCTS_INTEGRATION,
} from '../actions/actionTypes';
import {
  always,
  concat,
  defaultTo,
  ifElse,
  lensPath,
  lensProp,
  mergeLeft,
  mergeRight,
  over,
  pathOr,
  pipe,
  uniq,
  without,
} from 'ramda';

const initialState = {
  entities: {},
  selected: {},
};

export function getIntegrationPage(state, { result }) {
  const entities = pathOr([], ['entities', 'integrations'], result);
  return over(lensProp('entities'), mergeRight(entities), state);
}

export function getIntegrationList(state, { result }) {
  const entities = pathOr([], ['entities', 'integrations'], result);
  return over(lensProp('entities'), mergeLeft(entities), state);
}

export function selectProductsIntegration(state, { payload }) {
  const newState = over(
    lensPath(['selected', payload.integrationName]),
    ifElse(
      Array.isArray,
      pipe(concat(payload.productIds), uniq),
      always(payload.productIds),
    ),
    state,
  );

  window.localStorage.setItem(
    'selectedProductsIntegration',
    JSON.stringify(newState.selected),
  );

  return newState;
}

export function unselectProductsIntegration(state, { payload }) {
  const newState = over(
    lensPath(['selected', payload.integrationName]),
    pipe(defaultTo([]), without(payload.productIds)),
    state,
  );
  window.localStorage.setItem(
    'selectedProductsIntegration',
    JSON.stringify(newState.selected),
  );

  return newState;
}

export default createReducer(initialState, {
  [GET_INTEGRATION_PAGES.success]: getIntegrationPage,
  [GET_INTEGRATION_BY_ID.success]: getIntegrationList,
  [ADD_PRODUCT_INTEGRATION.success]: getIntegrationList,
  [REMOVE_PRODUCT_INTEGRATION.success]: getIntegrationList,
  [SELECT_PRODUCTS_INTEGRATION.success]: selectProductsIntegration,
  [UNSELECT_PRODUCTS_INTEGRATION.success]: unselectProductsIntegration,
});
