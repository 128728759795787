import { INIT_SOCKET } from '../actions/actionTypes';
import { io } from 'socket.io-client';

export const socketMiddleware = () => (next) => {
  let _socket = null;

  return (action) => {
    if (action.type === INIT_SOCKET.success) {
      if (_socket) {
        return _socket;
      }

      const socket = io();

      socket.on('notifications', (...args) => {
        console.log(args);
      });

      _socket = socket;
    }

    next(action);
  };
};
