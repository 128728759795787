import { 
  NAV_BAR_TYPES,
  SEARCH_BAR_TYPES,
  REGISTER_DIALOG_TYPES,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR
} from './actionTypes';

export const displayNavBar = display => ({
  type: NAV_BAR_TYPES.display,
  display
});

export const displayRegisterDialog = display => ({
  type: REGISTER_DIALOG_TYPES.display,
  display
});

export const displaySearchBar = display => ({
  type: SEARCH_BAR_TYPES.display,
  display
});

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});