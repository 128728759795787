import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { middleware as requestMW } from './requestMiddleware';
import { initSagas } from './initSagas';
import combinedReducer from './reducer';
import { middleware as authMiddleware } from './authentication';
import { socketMiddleware } from './middlewares/socket';

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

const initStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    authMiddleware,
    thunkMiddleware,
    requestMW,
    sagaMiddleware,
    socketMiddleware,
  ];

  const store = configureStore({
    reducer,
    middleware: [...middlewares],
    devTools: process.env.NODE_ENV !== 'production',
  });

  initSagas(sagaMiddleware);
  return store;
};

export const wrapper = createWrapper(initStore, {
  debug: false,
  serializeState: (state) => {
    try {
      return JSON.stringify(state);
    } catch (error) {
      return state;
    }
  },
  deserializeState: (state) => {
    try {
      return JSON.parse(state);
    } catch (error) {
      return state;
    }
  },
});
