import {
  USER_LOGIN_TYPES,
  USER_REGISTER_TYPES,
  USER_REGISTER_ANONYMOUS_TYPES,
  USER_SOCIAL_REGISTER_TYPES,
  USER_RESET_PASSWORD_REQUEST_TYPES,
  USER_RESET_PASSWORD_TYPES,
  USER_LOGOUT_TYPES,
  USER_LOAD_TYPES,
  USER_SET_TOKEN_TYPES,
} from './actionTypes';
import { getUserCollectionList } from 'common/store/actions';

export const register = (payload) => ({
  types: USER_REGISTER_TYPES,
  promise: (api) => api.auth.register(payload),
});

export const registerAnonymous = () => ({
  types: USER_REGISTER_ANONYMOUS_TYPES,
  promise: (api) => api.auth.registerAnonymous(),
});

export const login = (payload) => ({
  types: USER_LOGIN_TYPES,
  promise: (api) => api.auth.login(payload),
});

export const loginGoogle = (payload) => ({
  types: USER_SOCIAL_REGISTER_TYPES,
  promise: (api) => api.auth.loginGoogle(payload),
});

export const loginFacebook = (payload) => ({
  types: USER_SOCIAL_REGISTER_TYPES,
  promise: (api) => api.auth.loginFacebook(payload),
});

export const logout = () => ({ type: USER_LOGOUT_TYPES.success });

export const fetchUser = () => (dispatch) => {
  return dispatch({
    types: USER_LOAD_TYPES,
    promise: (api) => api.auth.fetchUser(),
  })
    .then(() => dispatch(getUserCollectionList()))
    .catch((error) => {
      console.log('Caught: ', error.message);
    });
};

export const setToken = (token) => ({
  type: USER_SET_TOKEN_TYPES.success,
  payload: { token },
});

export const resetPasswordRequest = (payload) => ({
  types: USER_RESET_PASSWORD_REQUEST_TYPES,
  promise: (api) => api.auth.resetPasswordRequest(payload),
});

export const resetPassword = (payload) => ({
  types: USER_RESET_PASSWORD_TYPES,
  promise: (api) => api.auth.resetPassword(payload),
});
