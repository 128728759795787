import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from 'common/constants';
import { useSelector } from 'react-redux';
import { equals, pathOr } from 'ramda';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    minWidth: 344,
  },
  typography: {
    fontWeight: 'bold',
    color: 'white',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    backgroundColor: '#fddc6c',
  },
  icons: {
    marginLeft: 'auto !important',
  },
  closeIcon: {
    color: 'white',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  success: {
    backgroundColor: `${colors.snackbarSuccess}`,
  },
  error: {
    backgroundColor: `${colors.snackbarError}`,
  },
  info: {
    backgroundColor: `${colors.snackbarInfo}`,
  },
  warning: {
    backgroundColor: `${colors.snackbarWarning}`,
  },
}));

const SnackMessage = (props) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const notifications = useSelector((store) => store.ui.notifications || []);
  const myNotification = useMemo(
    () => notifications.find((item) => item.key === props.id),
    [notifications, props.id],
  );
  const myNotificationVariant = useMemo(
    () => pathOr('info', ['options', 'variant'], myNotification),
    [myNotification],
  );
  // const Icon = variantIcon[props.variant];

  const handleDismiss = () => {
    closeSnackbar(props.id);
  };

  return (
    <Card className={classes.card} ref={props.snackRef}>
      <CardActions
        classes={{
          root: clsx(classes.actionRoot, {
            [classes.success]: equals(myNotificationVariant, 'success'),
            [classes.error]: equals(myNotificationVariant, 'error'),
            [classes.info]: equals(myNotificationVariant, 'info'),
            [classes.warning]: equals(myNotificationVariant, 'warning'),
          }),
        }}
      >
        <Typography variant="subtitle2" className={classes.typography}>
          {props.message}
        </Typography>
        <div className={classes.icons}>
          <IconButton className={classes.expand} onClick={handleDismiss}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </CardActions>
    </Card>
  );
};

SnackMessage.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  snackRef: PropTypes.any.isRequired,
};

export default React.forwardRef((props, ref) => (
  <SnackMessage {...props} snackRef={ref} />
));
