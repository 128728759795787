import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStore, Provider } from 'react-redux';

import { wrapper } from 'common/store';
import theme from 'common/theme';
import Notifier from 'common/components/Notifier';
import Snackbar from 'common/components/Snackbar';
import GlobalLoader from 'common/components/GlobalLoader';
import {
  routeChangeStart,
  routeChangeComplete,
  routeChangeError,
} from '../common/store/actions';

const RouterEffect = () => {
  const store = useStore();

  useEffect(() => {
    const onRouteChangeStart = () => {
      store.dispatch(routeChangeStart());
    };

    const onRouteChangeComplete = () => {
      store.dispatch(routeChangeComplete());
    };

    const onRouteChangeError = () => {
      store.dispatch(routeChangeError());
    };

    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.events.on('routeChangeError', onRouteChangeError);

    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
      Router.events.off('routeChangeError', onRouteChangeError);
    };
  }, []);

  return null;
};

const WrappedApp = ({ Component, ...rest }) => {
  const { pageProps } = rest;
  const { store } = wrapper.useWrappedStore(rest);

  return (
    <>
      <Head>
        <title>Dropseeker</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            content={(key, message) => <Snackbar id={key} message={message} />}
          >
            <CssBaseline />
            <Notifier />
            <GlobalLoader />
            <Component {...pageProps} />
            <RouterEffect />
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
};

WrappedApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

// WrappedApp.getInitialProps = wrapper.getInitialAppProps((store) => async ({ Component, ctx }) => {
//   const params = {
//     ...ctx,
//     store,
//   };
//
//   await initialize(params);
//
//   return {
//     pageProps: {
//       ...(Component.getInitialProps ? await Component.getInitialProps(params) : {}),
//     },
//   };
// });

export default WrappedApp;
