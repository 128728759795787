import { schema } from 'normalizr';
import { always, cond, propEq, propOr } from 'ramda';

export const user = new schema.Entity(
  'users',
  {},
  {
    processStrategy: (value) => ({
      ...value.data,
      id: value.id,
    }),
  },
);

export const category = new schema.Entity(
  'categories',
  {},
  {
    processStrategy: (value) => ({
      ...value.data,
      id: value.id,
      type: value.type ? value.type.toLowerCase() : null,
    }),
  },
);

export const product = new schema.Entity(
  'products',
  {},
  {
    processStrategy: (value) => ({
      ...propOr({}, 'data', value),
      update: value.update || null,
      id: value.id,
      type: value.type ? value.type.toLowerCase() : null,
    }),
  },
);

export const collection = new schema.Entity(
  'collections',
  {},
  {
    processStrategy: (value) => {
      return {
        ...value.data,
        id: value.id,
        type: value.type ? value.type.toLowerCase() : null,
      };
    },
  },
);

collection.define({
  entries: new schema.Array(
    {
      collections: new schema.Entity(
        'collections',
        {},
        {
          idAttribute: '_id',
        },
      ),
      products: product,
      categories: category,
    },
    (input) => {
      return cond([
        [propEq('Collection', 'type'), always('collections')],
        [propEq('Product', 'type'), always('products')],
        [propEq('Category', 'type'), always('categories')],
      ])(input);
    },
  ),
});

export const savedProducts = new schema.Entity(
  'savedProducts',
  {},
  {
    processStrategy: (value) => ({
      ...value.data,
      id: value.id,
      type: value.type ? value.type.toLowerCase() : null,
    }),
  },
);

export const myCollections = new schema.Entity(
  'myCollections',
  {
    entries: [product],
  },
  {
    processStrategy: (value) => ({
      ...value.data,
      id: value.id,
      type: value.type ? value.type.toLowerCase() : null,
    }),
  },
);

export const notifications = new schema.Entity(
  'notifications',
  {},
  {
    processStrategy: (value) => ({
      ...value.data,
      id: value.id,
    }),
  },
);

export const searchType = new schema.Entity('searchType', {});

export const search = new schema.Entity(
  'search',
  {
    indexes: [searchType],
  },
  {
    processStrategy: (value) => ({
      ...value.data,
      id: value.id,
      type: value.type ? value.type.toLowerCase() : null,
    }),
  },
);

export const integration = new schema.Entity(
  'integrations',
  {
    entries: [product],
  },
  {
    processStrategy: (value) => ({
      ...value.data,
      id: value.id,
    }),
  },
);
