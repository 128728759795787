import {
  CATEGORY_TYPES,
  COLLECTION_TYPES,
  EMAIL_TYPES,
  PRODUCT_TYPES,
  SEARCH_TYPES,
  SET_VERSION_NUMBER,
} from '../actions/actionTypes';
import createReducer from 'common/store/createReducer';

const initialState = {
  categoryLoading: false,
  collectionLoading: false,
  productLoading: false,
  searchLoading: false,
  emailSubscriberLoading: false,
  versionNumber: null,
};

export default createReducer(initialState, {
  // Category
  [CATEGORY_TYPES.loading]: (state) => {
    return {
      ...state,
      categoryLoading: true,
    };
  },
  [CATEGORY_TYPES.success]: (state) => {
    return {
      ...state,
      categoryLoading: false,
      categorySuccess: true,
    };
  },
  [CATEGORY_TYPES.failure]: (state) => {
    return {
      ...state,
      categoryLoading: false,
      categorySuccess: false,
    };
  },

  // Product
  [PRODUCT_TYPES.loading]: (state) => {
    return {
      ...state,
      productLoading: true,
    };
  },
  [PRODUCT_TYPES.success]: (state) => {
    return {
      ...state,
      productLoading: false,
      productSuccess: true,
    };
  },
  [PRODUCT_TYPES.failure]: (state) => {
    return {
      ...state,
      productLoading: false,
      productSuccess: false,
    };
  },

  // Collection
  [COLLECTION_TYPES.loading]: (state) => {
    return {
      ...state,
      collectionLoading: true,
    };
  },
  [COLLECTION_TYPES.success]: (state) => {
    return {
      ...state,
      collectionLoading: false,
      collectionSuccess: true,
    };
  },
  [COLLECTION_TYPES.failure]: (state) => {
    return {
      ...state,
      collectionLoading: false,
      collectionSuccess: false,
    };
  },

  // Search
  [SEARCH_TYPES.loading]: (state) => {
    return {
      ...state,
      searchLoading: true,
    };
  },
  [SEARCH_TYPES.success]: (state) => {
    return {
      ...state,
      searchLoading: false,
      searchSuccess: true,
    };
  },
  [SEARCH_TYPES.fail]: (state) => {
    return {
      ...state,
      searchLoading: false,
      searchSuccess: false,
    };
  },

  // Email
  [EMAIL_TYPES.loading]: (state) => {
    return {
      ...state,
      emailSubscriberLoading: true,
    };
  },
  [EMAIL_TYPES.success]: (state) => {
    return {
      ...state,
      emailSubscriberLoading: false,
      emailSubscriberSuccess: true,
    };
  },
  [EMAIL_TYPES.failure]: (state) => {
    return {
      ...state,
      emailSubscriberLoading: false,
      emailSubscriberSuccess: false,
    };
  },
  [SET_VERSION_NUMBER.success]: (state, action) => {
    return {
      ...state,
      versionNumber: action.payload.value,
    };
  },
});
