import api from 'common/api';

export const middleware = (store) => (next) => (action) => {
  if (typeof action === 'function') {
    return action(store.dispatch, store.getState);
  }

  const { promise, types, ...rest } = action;

  if (!promise) {
    return next(action);
  }

  const { loading: REQUEST, success: SUCCESS, failure: FAILURE } = types;

  next({ ...rest, type: REQUEST });

  const actionPromise = promise(api);

  actionPromise
    .then(
      (result) => next({ ...rest, result, type: SUCCESS }),

      (error) => next({ ...rest, error, type: FAILURE }),
    )
    .catch((error) => {
      console.log('error', error);
      next({ ...rest, error, type: FAILURE });
    });

  return actionPromise;
};
