import { DISCOVER_LIST_UPDATE } from './actionTypes';

export function discoverListUpdate(list) {
  return {
    type: DISCOVER_LIST_UPDATE,
    payload: {
      list,
    },
  };
}
