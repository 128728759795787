import createReducer from '../createReducer';
import { DISCOVER_LIST_UPDATE } from '../actions/actionTypes';
import { propOr } from 'ramda';

const initialState = {
  list: [],
};

export function updateDiscoverList(state, { payload }) {
  return {
    ...state,
    list: propOr([], 'list', payload),
  };
}

export default createReducer(initialState, {
  [DISCOVER_LIST_UPDATE]: updateDiscoverList,
});
