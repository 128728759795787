import { lensProp, mergeLeft, over, pathOr } from 'ramda';
import createReducer from '../createReducer';
import { USER_GET_BILLING_HISTORY_TYPES } from '../actions/actionTypes';

const initialState = {
  entities: {},
};

export function getBillingHistory(state, { result }) {
  const entities = pathOr([], ['list'], result);
  return over(lensProp('entities'), mergeLeft(entities), state);
}

export default createReducer(initialState, {
  [USER_GET_BILLING_HISTORY_TYPES.success]: getBillingHistory,
});
