import {
  SEARCH_SUGGESTION_START,
  SEARCH_SUGGESTION_REQUEST_FAIL,
  SEARCH_SUGGESTION_STOP,
  SEARCH_SUGGESTION_RESULTS_UPDATE,
  SEARCH_SUGGESTION_RESULTS_HIDE,
  SEARCH_SUGGESTION_RESULTS_SHOW,
  SEARCH_SUGGESTION_RESULTS_CLEAR,
  SEARCH_SUGGESTION_QUERY_UPDATE
} from '../actions/actionTypes';

import createReducer from 'common/store/createReducer';
import { keys, pathOr, pipe } from 'ramda';

const initialState = {
  query: '',
  hidden: true,
  loading: false,
  searchIds: []
};

export function startSearchSuggestion(state) {
  return {
    ...state,
    loading: true
  };
}

export function stopSearchSuggestion(state) {
  return {
    ...state,
    loading: false
  };
}

export function updateSearchSuggestionResults(state, action) {
  const searchIds = pipe(
    pathOr({}, ['payload', 'entities', 'search']),
    keys
  )(action);

  return {
    ...state,
    searchIds
  };
}

export function hideSearchSuggestionResults(state) {
  return {
    ...state,
    hidden: true
  };
}

export function showSearchSuggestionResults(state) {
  return {
    ...state,
    hidden: false
  };
}

export function clearSearchSuggestionResults(state) {
  return {
    ...state,
    query: '',
    searchIds: []
  };
}

export function updateSearchSuggestionQuery(state, { payload: { query } }) {
  return {
    ...state,
    query
  };
}

export default createReducer(initialState, {
  [SEARCH_SUGGESTION_START]: startSearchSuggestion,
  [SEARCH_SUGGESTION_REQUEST_FAIL]: stopSearchSuggestion,
  [SEARCH_SUGGESTION_STOP]: stopSearchSuggestion,
  [SEARCH_SUGGESTION_RESULTS_UPDATE]: updateSearchSuggestionResults,
  [SEARCH_SUGGESTION_RESULTS_HIDE]: hideSearchSuggestionResults,
  [SEARCH_SUGGESTION_RESULTS_SHOW]: showSearchSuggestionResults,
  [SEARCH_SUGGESTION_RESULTS_CLEAR]: clearSearchSuggestionResults,
  [SEARCH_SUGGESTION_QUERY_UPDATE]: updateSearchSuggestionQuery
});
